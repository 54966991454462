<style scoped>
    @import '../../assets/css/authentication/profile.scss';
</style>
<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        <strong>{{currentUser.username}}</strong> Profile
      </h3>
    </header>
    <p>
      <strong>Token:</strong>
      {{currentUser.accessToken.substring(0, 20)}} ... {{currentUser.accessToken.substr(currentUser.accessToken.length - 20)}}
    </p>
    <p>
      <strong>Id:</strong>
      {{currentUser.id}}
    </p>
    <p>
      <strong>Email:</strong>
      {{currentUser.email}}
    </p>
    <strong>Authorities:</strong>
    <ul>
      <li v-for="role in currentUser.roles" :key="role">{{role}}</li>
    </ul>
  </div>
</template>

<script>
// That component displays all the infos of the user
export default {
  name: 'Profile',
  computed: {
    currentUser() {
      // We have added a 'user' entry in the localstorage in the file
      // servicesauthentication\auth.service.js

      // this.$store is a global variable defined in the main.js (root app)
      // state defined ==> store/authentication/auth.module.js
      console.log("Register loggedIn state =", this.$store.state.auth.user)
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>